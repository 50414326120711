import React from "react";
import Card from "react-bootstrap/Card";
import { IconBase } from "react-icons";
import { ImPointRight } from "react-icons/im";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Iheb Soula </span>
            <br />
            <br />

            Currently employed as a Software R&D Engineer at <FontAwesomeIcon icon={faBriefcase} /> <a href="https://www.esigelec.fr/fr/service-innovation-recherche-et-developpement">IRSEEM-ESIGELEC</a> .
            <br />
            I'm an Engineer specialized in telecommunications and embedded systems,
            with a passion for the Internet of Things, embedded vision, and robotics projects.
            <br />
            <br />
            <p>
              I'm also a freelancer in software development on <FontAwesomeIcon icon={faBriefcase} /> <a href="https://www.upwork.com/freelancers/~01d10a8e7a699cdc83">Upwork</a>
              .
            </p>
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Video Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Watching Animes/Movies
            </li>
            <li className="about-activity">
              <ImPointRight /> Making Embedded Projects
            </li>
          </ul>
          <p style={{ color: "rgb(155 126 172)" }}>
            "In the world of embedded systems, every line of code and every circuit has the potential to create something revolutionary!"{" "}
          </p>
          <footer className="blockquote-footer">Iheb Soula</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
