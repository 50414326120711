import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <div className="typewriter-container">
      <Typewriter
        options={{
          strings: [
            "Embedded Software Engineer",
            "Freelancer",
            "IoT Enthusiast",
          ],
          autoStart: true,
          loop: true,
          deleteSpeed: 50,
          pauseFor: 1500,  // Adds a small pause before the next string starts
          delay: 75,       // Controls typing speed for a smoother effect
        }}
      />
    </div>
  );
}

export default Type;
